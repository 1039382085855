@import-normalise;
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'https://unpkg.com/react-phone-number-input@3.x/bundle/style.css';

@font-face {
  font-family: 'NotoSans';
  src: url('assets/fonts/NotoSans-Black.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-family: 'NotoSans';
  src: url('assets/fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'NotoSans';
  src: url('assets/fonts/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'NotoSans';
  src: url('assets/fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: 900;
  font-family: 'NotoSans';
  src: url('assets/fonts/NotoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

* {
  letter-spacing: -0.75px;
  box-sizing: border-box;
  font-family:
    'NotoSans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

button:focus {
  outline: none;
}

button:active {
  outline: none;
}
